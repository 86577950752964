@tailwind base;
@tailwind components;
@tailwind utilities;

.fis{
    display: flex !important;
    flex-direction: column !important;
}

/* body{
    background-color: rgba(195, 214, 255, 0.196);
} */
 body{
    background-color: #f5f7fb;
 }
 .scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
.stok-li{
    @apply border rounded gap-2 w-full p-2 flex items-center font-semibold mb-1;
}
.btn-ad{
    @apply py-2 px-4 rounded bg-green-500 text-white flex items-center gap-2;
}

.masa-type-btn{
    @apply rounded py-2 px-4 font-semibold text-sm uppercase border;
}
.nav-link{
    @apply relative rounded text-blue-600 bg-slate-50 py-2 px-3 flex gap-2 items-center border uppercase text-sm font-semibold hover:border-blue-600;
}
.dropdown {
    @apply z-10 top-[calc(100%+10px)] absolute list-none bg-white rounded py-1 min-w-full border
}
.dropdown-link{
    @apply whitespace-nowrap py-1 px-3 block
}
.masa-detail-item{
    @apply rounded border w-[calc((100%-5px))] aspect-[5/4] flex items-center font-medium  hover:cursor-pointer
}

.form-item{
    @apply border rounded py-2 px-3 outline-none text-sm font-medium
}

.masa-siparis input[type="number"]::-webkit-inner-spin-button,
.masa-siparis input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hesap-kes-item{
    @apply flex items-center gap-2 p-2 border w-[calc((100%-8px)/2)]
}
.checkmark {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #4bb71b;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #4bb71b;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    position:relative;
    top: 5px;
    right: 5px;
   margin: 0 auto 20px;
}
.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #4bb71b;
    fill: #fff;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
 
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%, 100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #4bb71b;
    }
}

/* styles.css */
@media (max-width: 950px) {
    .max-w-mobile {
        max-width: 330px; /* Исправлено для обеспечения полной ширины на мобильных устройствах */
    }
}